import { PropertyData } from '../components/Types/GameTypes';

export const Properties: { [key: string]: PropertyData } = {
  wheat_field: {
    color: 'blue',
    cost: 1,
    income: 1,
    dice: [1],
    type: 'WHEAT',
  },
  ranch: {
    color: 'blue',
    cost: 1,
    income: 1,
    dice: [2],
    type: 'COW',
  },
  bakery: {
    color: 'green',
    cost: 1,
    income: 1,
    dice: [2, 3],
    type: 'BREAD',
  },
  cafe: {
    color: 'red',
    cost: 2,
    income: 1,
    dice: [3],
    type: 'CUP',
  },
  convenience_store: {
    color: 'green',
    cost: 2,
    income: 3,
    dice: [4],
    type: 'BREAD',
  },
  forest: {
    color: 'blue',
    cost: 3,
    income: 1,
    dice: [5],
    type: 'GEAR',
  },
  stadium: {
    color: 'purple',
    cost: 6,
    income: '2 per PLAYER',
    dice: [6],
    type: 'TOWER',
  },
  tv_station: {
    color: 'purple',
    cost: 7,
    income: '5 per PLAYER ONCE',
    dice: [6],
    type: 'TOWER',
  },
  tax_office: {
    color: 'purple',
    cost: 4,
    income: 'HALF>10 per PLAYER ONCE',
    dice: [8, 9],
    type: 'TOWER',
  },
  publisher: {
    color: 'purple',
    cost: 5,
    income: '1 per PLAYER per CUP and BREAD',
    dice: [7],
    type: 'TOWER',
  },
  business_center: {
    color: 'purple',
    cost: 8,
    income: 0,
    dice: [6],
    type: 'TOWER',
  },
  cheese_factory: {
    color: 'green',
    cost: 5,
    income: '3 per COW',
    dice: [7],
    type: 'FACTORY',
  },
  furniture_factory: {
    color: 'green',
    cost: 3,
    income: '3 per GEAR',
    dice: [8],
    type: 'FACTORY',
  },
  mine: {
    color: 'blue',
    cost: 6,
    income: 5,
    dice: [9],
    type: 'GEAR',
  },
  family_restaraunt: {
    color: 'red',
    cost: 3,
    income: 2,
    dice: [9, 10],
    type: 'CUP',
  },
  apple_orchard: {
    color: 'blue',
    cost: 3,
    income: 3,
    dice: [10],
    type: 'WHEAT',
  },
  fruit_and_vegetable_market: {
    color: 'green',
    cost: 2,
    income: '2 per WHEAT',
    dice: [11, 12],
    type: 'FRUIT',
  },
  train_station: {
    color: 'orange',
    cost: 4,
    income: 0,
    dice: [0],
    type: 'LANDMARK',
    effect: 'DICE',
  },
  shopping_mall: {
    color: 'orange',
    cost: 10,
    income: 0,
    dice: [0],
    type: 'LANDMARK',
    effect: 'BREAD CUP +1',
  },
  amusement_park: {
    color: 'orange',
    cost: 16,
    income: 0,
    dice: [0],
    type: 'LANDMARK',
    effect: 'SECONDTURN ONDOUBLE',
  },
  radio_tower: {
    color: 'orange',
    cost: 22,
    income: 0,
    dice: [0],
    type: 'LANDMARK',
    effect: 'REROLL ONCE',
  },
  city_hall: {
    color: 'orange',
    cost: 0,
    income: 0,
    dice: [0],
    type: 'LANDMARK',
    effect: 'MONEY SUPPORT',
  }
};

export const PropertiesLocalized: { [key: string]: string } = {
  wheat_field: 'Wheat Field',
  ranch: 'Ranch',
  forest: 'Forest',
  mine: 'Mine',
  apple_orchard: 'Apple Orchard',
  bakery: 'Bakery',
  convenience_store: 'Convenience Store',
  cheese_factory: 'Cheese Factory',
  furniture_factory: 'Furniture Factory',
  fruit_and_vegetable_market: 'Fruit Market',
  cafe: 'Cafe',
  family_restaraunt: 'Family Restaurant',
  stadium: 'Stadium',
  tv_station: 'TV Station',
  business_center: 'Business Center',
  train_station: 'Central Station',
  shopping_mall: 'Shopping Mall',
  amusement_park: 'Amusement Park',
  radio_tower: 'Radio Tower',
  publisher: 'Publisher',
  tax_office: 'Tax Office',
  city_hall: 'City Hall',
};
